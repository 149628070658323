// viva-packaging

import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SponsorshipCard from '../../components/blog/SponsorshipCard'

function VivaPackaging() {
    return (
        <div className="container">
            <PageTitle title={'FTG Foods introduces the Viva brand'} />
            <Breadcrumbs />
            <div className="row">
                <div className="col-lg-8">
                    <img
                        className="img-fluid rounded"
                        src="/img/Blog Banners/900x300/viva.png"
                        alt=""
                    />
                    <hr />
                    <p>Posted on July 18, 2021</p>
                    <hr />
                    <p className="lead">
                        We've introducing a new packaging and brand for our
                        products to better reflect our cultural identity, and to
                        make improvements on the product based on your feedback.
                        In our Viva brand, you'll now find that all packages are
                        12 large pieces, and are a standard 600g. We've also
                        added more colourful and easier to read cooking
                        instructions.
                    </p>

                    <br />

                    <a href="/products/viva" className="btn btn-primary">
                        See our Viva page in Products &rarr;
                    </a>
                    <hr />
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}
export default VivaPackaging
