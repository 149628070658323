import React from "react";

function CallToAction() {
  return (
    <div>
      <hr />
      <div className="row my-4">
        <div className="col-md-8">
          <p>
            Do you want to learn more about FTG Foods or our products? Got a
            great anecdote? Is there a community event that we might be able to
            support? Contact us today!
          </p>
        </div>
        <div className="col-md-4 float-right">
          <a className="btn btn-lg w-100 btn-danger" href="/contact-us">
            Contact Us!
          </a>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
