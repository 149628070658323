import React from "react";

function AboutProducts() {
    return (
        <div>
            <h1 className="mt-4">About Our Products</h1>
            <div className="row mt-3">
                <div className="col-lg-4 mb-4">
                    <div className="card h-100">
                        <h4 className="card-header">Purely Filipino-Canadian</h4>
                        <div className="card-body">
                            <p className="card-text">
                                As a Filipino-Canadian owned and operated company, we are proud to
                                serve 100% Canadian meats in all of our products with authentic
                                Filipino tastes. With an understanding of the challenges of
                                newcomers to Canada, we make a point of also supporting local
                                initiatives like cultural community events and sporting team
                                sponsorships.
                            </p>
                        </div>
                        <div className="card-footer">
                            <a href="/about-us" className="btn btn-primary">Learn More</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card h-100">
                        <h4 className="card-header">Quality You Can Count On</h4>
                        <div className="card-body">
                            <p className="card-text">
                                Being the first federally approved red hotdog manufacturer in Canada
                                has given us time to make sure our products are of the highest
                                quality. As federally approved manufacturers, we not only comply
                                with the Safe Foods Regulation Act of Canada, but are also rated and
                                approved for sales in the USA.
                            </p>
                        </div>
                        <div className="card-footer">
                            <a href="/blog/federal" className="btn btn-primary">Learn More</a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="card h-100">
                        <h4 className="card-header">Authentic Flavours</h4>
                        <div className="card-body">
                            <p className="card-text">
                                All our products have authentic Filipino flavours. We understand the
                                need to continue our culture as Filipino-Canadians, and are proud to
                                share the flavours of our culture with our community. In addition to
                                our famous hotdogs, we also sell specialty products like longganisa,
                                kikiam, tocino, beef tapa, fiesta ham, and fishballs.
                            </p>
                        </div>
                        <div className="card-footer">
                            <a href="/products" className="btn btn-primary">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutProducts