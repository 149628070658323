import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'

function Layout() {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Navbar />
            <div className={'flex-fill mt-5 pt-2'}>
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}

export default Layout
