import ProfileCarousel from "../components/home/profile-carousel/ProfileCarousel";
import AboutProducts from "../components/home/about-products/AboutProducts";
import TopProducts from "../components/home/top-products/TopProducts";
import CommunityEngagement from "../components/home/community-engagement/CommunityEngagement";
import CallToAction from "../components/home/call-to-action/CallToAction";

function Home() {
  return (
    <div>
      <ProfileCarousel />
      <div className={"container"}>
        <AboutProducts />
        <TopProducts />
        <CommunityEngagement />
        <CallToAction />
      </div>
    </div>
  );
}

export default Home;
