function FooterLayout() {
    return (
        <footer className="py-3 bg-light">
            <p className="m-0 text-center text-black">
                Copyright &copy; FTG Foods Canada Ltd. 2023
            </p>
        </footer>
    )
}

export default FooterLayout