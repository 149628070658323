function ContactDetails() {
    return (
        <div className="row">
            <div className="col-lg-8 mb-4">
                <iframe
                    width="100%"
                    height="400px"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8206.904898181972!2d-113.98224005615978!3d50.989859890628956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717a125f1ff7b1%3A0x89810dd6db080219!2s7003%2030%20St%20SE%2C%20Calgary%2C%20AB%20T2C%201N6!5e0!3m2!1sen!2sca!4v1589498427978!5m2!1sen!2sca"
                ></iframe>
            </div>

            <div className="col-lg-4 mb-4">
                <h3>Contact Details</h3>
                <p>
                    Bay 13 - 7007 30 St SE
                    <br />
                    Calgary, AB T2C 1N6
                    <br />
                </p>
                <p>
                    <abbr title="Phone">P</abbr>: (587) 969-2223
                </p>
                <p>
                    <abbr title="Email">E</abbr>:
                    <a href="mailto:ftgfoodsltd@gmail.com">
                        ftgfoodsltd@gmail.com{' '}
                    </a>
                </p>
                <p>
                    <abbr title="Hours">H</abbr>: Please schedule an appointment
                    to let us know you're coming by.
                </p>
            </div>
        </div>
    )
}
export default ContactDetails
