import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface ContactUsFormValues {
    emailAddress: string
    fullName: string
    message: string
    phoneNumber: string
}

function ContactUsForm() {
    const handleSubmit = async (
        values: ContactUsFormValues,
        { resetForm }: FormikHelpers<ContactUsFormValues>
    ) => {
        const {
            emailAddress: email,
            fullName: name,
            message,
            phoneNumber: phone,
        } = values ?? {}
        const response = await fetch(
            'https://nodejs-serverless-function-express-three-sage.vercel.app/api/email',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    name,
                    message,
                    phone,
                }),
            }
        )
        const result = await response.json()
        if (result.status === 200) {
            toast.success(
                'Thanks for contacting us, we will respond as soon as we can.',
                {
                    position: 'bottom-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: 'light',
                }
            )
            resetForm()
        } else {
            toast.error(
                'Something went wrong, please call or email us directly!',
                {
                    position: 'bottom-left',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                }
            )
        }
    }

    const validationSchema = () =>
        yup.object().shape({
            fullName: yup
                .string()
                .trim()
                .min(2, 'Must be greater than 3 chars')
                .required('Please enter a name'),
            phoneNumber: yup
                .string()
                .matches(
                    /^[0-9 ]+(?:-[0-9 ]+)*$/,
                    'Only numbers, spaces and hyphens are valid for this field.'
                ),
            emailAddress: yup
                .string()
                .trim()
                .email('Please provide a valid email address')
                .required('An email address is required.'),
            message: yup
                .string()
                .trim()
                .min(5, 'Must be greater than 5 chars')
                .required('Please enter content (at least 5 chars)'),
        })

    const fields = [
        { label: 'Full Name:', name: 'fullName' },
        { label: 'Phone Number:', name: 'phoneNumber' },
        { label: 'Email Address:', name: 'emailAddress' },
        {
            label: 'Message:',
            name: 'message',
            component: 'textarea',
            rows: 10,
        },
    ]

    return (
        <div>
            <div className="row">
                <div className="col-lg-8 mb-4">
                    <h3>Send us a Message</h3>

                    <Formik
                        initialValues={{
                            emailAddress: '',
                            phoneNumber: '',
                            message: '',
                            fullName: '',
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema()}
                    >
                        <Form className="max-w-3xl px-2 py-2 my-2 mx-auto rounded">
                            <div className="w-100">
                                {fields.map((field, index) => {
                                    const { label, name, component, rows } =
                                        field ?? {}
                                    return (
                                        <div
                                            className="mb-2 w-100 "
                                            key={index}
                                        >
                                            <label
                                                htmlFor={name}
                                                className="w-100 mb-2 text-sm font-bold text-gray-700"
                                            >
                                                {label}
                                            </label>
                                            <br />
                                            <Field
                                                type="text"
                                                name={name}
                                                component={component}
                                                rows={rows}
                                                className="w-100 px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline"
                                            ></Field>
                                            <ErrorMessage name={name}>
                                                {(errorMessage: any) => (
                                                    <div className="text-danger">
                                                        {errorMessage}
                                                    </div>
                                                )}
                                            </ErrorMessage>
                                        </div>
                                    )
                                })}
                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary px-4 py-2 mt-2 text-base font-medium text-white"
                                    >
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                    <ToastContainer />
                </div>
            </div>
        </div>
    )
}

export default ContactUsForm
