import { ProductType } from '../../lib/ProductType'

function ProductDetails({ product }: { product: ProductType }) {
    return (
        <div>
            <div className="row">
                <div className="col-md-8">
                    <img
                        className="img-fluid"
                        src={`/img/Products/${product.imgSlug}`}
                        alt=""
                    />
                </div>

                <div className="col-md-4">
                    <h3 className="my-3">{product.name}</h3>
                    <p>{product.description}</p>
                    <h3 className="my-3">Product Details</h3>
                    <ul>
                        <li>Main Ingredient: {product.mainIngredient}</li>
                        <li>Package weight: {product.weight}</li>
                        <li>Packs per box: {product.packsPerBox}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default ProductDetails
