import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SponsorshipCard from '../../components/blog/SponsorshipCard'

function Stampede() {
    return (
        <div className="container">
            <PageTitle title={'Stampede Breakfast'} />
            <Breadcrumbs />
            <div className="row">
                <div className="col-lg-8">
                    <img
                        className="img-fluid rounded"
                        src="/img/Blog Banners/900x300/stampede.png"
                        alt=""
                    />
                    <hr />
                    <p>Posted on April 20, 2020</p>
                    <hr />
                    <p className="lead">
                        We're very sad to announce that due to the COVID-19
                        situation, we will be unable to host our annual Stampede
                        breakfast.
                    </p>

                    <p>
                        We plan however, to re-schedule the event as a continued
                        thank you for all of our loyal customers. We will
                        announce the event's rescheduling as soon as we're able.
                    </p>
                    <hr />
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}
export default Stampede
