import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SponsorshipCard from '../../components/blog/SponsorshipCard'

function OntarioSummerSale() {
    return (
        <div className="container">
            <PageTitle
                title={
                    'Check out our new promotional sales in Vaughan, Etobicoke, Mississauaga and Scarborough'
                }
            />
            <Breadcrumbs />
            <div className="row">
                <div className="col-lg-8">
                    <img
                        className="img-fluid rounded"
                        src="/img/Blog Banners/900x300/ontario-summer-sale.png"
                        alt=""
                    />
                    <hr />
                    <p>Posted on July 24, 2021</p>
                    <hr />
                    <p className="lead">
                        We are thrilled to get our product out into the Ontario
                        market, and to help spread the word, we've built
                        promotions with specialty supermarkets to offer our
                        products at a promotional summer rate. To help you find
                        the best deal on Viva products near you, we've listed
                        the 5 supermarkets running promotions right now.
                    </p>

                    <br />

                    <div>
                        <h4>
                            <b>
                                Season's Foodmart in Markham (as advertised in
                                the Balita Newspaper)
                            </b>
                        </h4>
                        <small>7181 Yonge St, Thornhill, ON L3T 0C7</small>
                        <img
                            src="/img/2021-promotions/seasons-markham-balita.jpg"
                            className="img-fluid"
                        />
                        <br />
                        <img
                            src="/img/2021-promotions/seasons-markham-flyer.jpg"
                            className="img-fluid"
                        />
                    </div>

                    <br />

                    <div>
                        <h4>
                            <b>Nation's Fresh Foods in Vaughan</b>
                        </h4>
                        <small>7600 Weston Rd, Woodbridge, ON L4L 8B7</small>
                        <img
                            src="/img/2021-promotions/nations-vaughan.jpg"
                            className="img-fluid"
                        />
                    </div>

                    <br />

                    <div>
                        <h4>
                            <b>Fresh Value in Etobicoke</b>
                        </h4>
                        <small>2267 Islington Ave, Etobicoke, ON M9W 3W7</small>
                        <img
                            src="/img/2021-promotions/fresh-value-etobicoke-toronto.jpeg"
                            className="img-fluid"
                        />
                    </div>

                    <br />

                    <div>
                        <h4>
                            <b>BTrust in Mississauga</b>
                        </h4>
                        <small>
                            1177 Central Pkwy W, Mississauga, ON L5C 4P3
                        </small>
                        <img
                            src="/img/2021-promotions/btrust-mississauga.jpg"
                            className="img-fluid"
                        />
                    </div>

                    <br />

                    <div>
                        <h4>
                            <b>Jian Hing in Scarborough</b>
                        </h4>
                        <small>678 Markham Rd, Scarborough, ON M1H 2A7</small>
                        <img
                            src="/img/2021-promotions/jian-hing-scarborough.jpg"
                            className="img-fluid"
                        />
                    </div>

                    <br />
                    <hr />
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}
export default OntarioSummerSale
