import fiestapasta from './fiestapasta.png'
import grilledcheese from './grilledcheese.png'
import macaronisoup from './macaronisoup.png'

function RecipeList() {
    return (
        <div>
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                        <a href="/recipes/fiesta-pasta">
                            <img
                                className="card-img-top"
                                src={fiestapasta}
                                alt=""
                            />
                        </a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="/recipes/fiesta-pasta">
                                    Meaty Juicy Fiesta Pasta
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                        <a href="/recipes/macaroni-soup">
                            <img
                                className="card-img-top"
                                src={macaronisoup}
                                alt=""
                            />
                        </a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="/recipes/macaroni-soup">
                                    Meaty Juicy Macaroni Soup
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 portfolio-item">
                    <div className="card h-100">
                        <a href="/recipes/grilled-cheese">
                            <img
                                className="card-img-top"
                                src={grilledcheese}
                                alt=""
                            />
                        </a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="/recipes/grilled-cheese">
                                    Cheesy Juicy Grilled Cheese
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecipeList
