import luckysupermarket from './luckysupermarket.png';
import seafoodcity from './seafoodcity.png';
import hongkong from './hongkong.png';
import henlong from './henlong.png';
import nofrills from './nofrills.png';
import coop from './coop.png';

function OurCustomers() {
    return (
        <div>
            <h2>Our Customers</h2>
            <div className={"d-flex flex-wrap"}>
                <div className="row">
                </div>
                <div className="col-lg-2 col-sm-4 mb-4">
                    <img className="img-fluid" src={luckysupermarket} alt=""/>
                </div>
                <div className="col-lg-2 col-sm-4 mb-4">
                    <img className="img-fluid" src={seafoodcity} alt=""/>
                </div>
                <div className="col-lg-2 col-sm-4 mb-4">
                    <img className="img-fluid" src={hongkong} alt=""/>
                </div>
                <div className="col-lg-2 col-sm-4 mb-4">
                    <img className="img-fluid" src={henlong} alt=""/>
                </div>
                <div className="col-lg-2 col-sm-4 mb-4">
                    <img className="img-fluid" src={nofrills} alt=""/>
                </div>
                <div className="col-lg-2 col-sm-4 mb-4">
                    <img className="img-fluid" src={coop} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default OurCustomers