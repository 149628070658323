import { useLocation } from 'react-router-dom'
function Breadcrumbs() {
    const location = useLocation()
    const paths = location.pathname.split('/')
    paths[0] = 'Home'
    const names = [...paths]
    for (let pathIndex = 1; pathIndex < paths.length; pathIndex++) {
        names[pathIndex] = paths[pathIndex]
            .replace(/-/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    }

    return (
        <ol
            className="breadcrumb rounded"
            style={{ backgroundColor: '#e9ecef', padding: 10 }}
        >
            {paths.map((path, index) => (
                <li
                    className={`breadcrumb-item ${
                        index === paths.length - 1 ? 'active' : ''
                    }`}
                    key={index}
                >
                    {index === paths.length - 1 ? (
                        <span>{names[index]}</span>
                    ) : (
                        <span>
                            {index === 0 ? <a href="/">{names[index]}</a> : ''}
                            {index === 1 ? (
                                <a href={`/${path}`}>{names[index]}</a>
                            ) : (
                                ''
                            )}
                            {index === 2 ? (
                                <a href={`/${paths[1]}/${path}`}>
                                    {names[index]}
                                </a>
                            ) : (
                                ''
                            )}
                        </span>
                    )}
                </li>
            ))}
        </ol>
    )
}

export default Breadcrumbs
