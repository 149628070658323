import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SponsorshipCard from '../../components/blog/SponsorshipCard'

function Covid19() {
    return (
        <div className="container">
            <PageTitle title={'COVID-19 Response'} />
            <Breadcrumbs />
            <div className="row">
                <div className="col-lg-8">
                    <img
                        className="img-fluid rounded"
                        src="/img/Blog Banners/900x300/covid.png"
                        alt=""
                    />
                    <hr />
                    <p>Posted on May 23, 2020</p>
                    <hr />
                    <p className="lead">
                        In trying times, we reach out to our comfort foods.
                        While soul foods will help us to get through these
                        lonely times, we must ensure that the products are made
                        with with the highest degree of safety for employees and
                        consumers in mind. With that in mind, we've taken on the
                        following initiatives:
                    </p>

                    <ul>
                        <li>
                            Additional policies ensuring our staff stay home
                            when sick
                        </li>
                        <li>
                            Policies which support staff needing to take time
                            off work to ensure there is no pressure to come in
                            when sick.
                        </li>
                        <li>Additional investments in PPE for our staff</li>
                        <li>
                            Sanitization of all surfaces touched in our supply
                            chain and which ultimately comes in contact with
                            anything coming in and out of our facility.
                        </li>
                    </ul>
                    <hr />
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}
export default Covid19
