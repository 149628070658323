import { Route, Routes } from 'react-router-dom'
import Layout from './layouts/Layout'
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import Products from './pages/Products'
import CookingInstructions from './pages/CookingInstructions'
import Recipes from './pages/Recipes'
import ContactUs from './pages/ContactUs'
import Blog from './pages/Blog'
import NoMatch from './pages/404'
import Viva from './pages/products/Viva'
import Hotdogs from './pages/products/Hotdogs'
import SpecialtyMeats from './pages/products/SpecialtyMeats'
import Seafood from './pages/products/Seafood'
import FindRetailers from './pages/FindRetailers'
import ProductDetails from './pages/Product'
import Recipe from './pages/Recipe'
import Federal from './pages/blog/Federal'
import TorontoStreetFestival from './pages/blog/TorontoStreetFestival'
import SpicyHotdogs from './pages/blog/SpicyHotdogs'
import OntarioSummerSale from './pages/blog/OntarioSummerSale'
import GreaterTorontoArea from './pages/blog/GreaterTorontoArea'
import VivaPackaging from './pages/blog/VivaPackaging'
import Covid19 from './pages/blog/Covid19'
import Stampede from './pages/blog/Stampede'

export default function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route
                        path="/products/:category/:id"
                        element={<ProductDetails />}
                    />
                    <Route path="/products/viva" element={<Viva />} />
                    <Route path="/products/hotdogs" element={<Hotdogs />} />
                    <Route
                        path="/products/specialty-meats"
                        element={<SpecialtyMeats />}
                    />
                    <Route path="/products/seafood" element={<Seafood />} />
                    <Route path="/products" element={<Products />} />
                    <Route
                        path="cooking-instructions"
                        element={<CookingInstructions />}
                    />
                    <Route path="recipes/:recipe" element={<Recipe />} />
                    <Route path="recipes" element={<Recipes />} />
                    <Route path="contact-us" element={<ContactUs />} />
                    <Route path="contact" element={<ContactUs />} />
                    <Route path="blog/covid-19" element={<Covid19 />} />
                    <Route path="blog/federal" element={<Federal />} />
                    <Route
                        path="blog/greater-toronto-area"
                        element={<GreaterTorontoArea />}
                    />
                    <Route
                        path="blog/ontario-summer-sale"
                        element={<OntarioSummerSale />}
                    />
                    <Route
                        path="blog/spicy-hotdogs"
                        element={<SpicyHotdogs />}
                    />
                    <Route path="blog/stampede" element={<Stampede />} />
                    <Route
                        path="blog/toronto-street-festival"
                        element={<TorontoStreetFestival />}
                    />
                    <Route
                        path="blog/viva-packaging"
                        element={<VivaPackaging />}
                    />
                    <Route path="blog" element={<Blog />} />
                    <Route path="find-retailers" element={<FindRetailers />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    )
}
