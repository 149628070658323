import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SponsorshipCard from '../../components/blog/SponsorshipCard'

function SpicyHotdogs() {
    return (
        <div className="container">
            <PageTitle
                title={'FTG Foods adds spicy hotdogs to their Viva lineup'}
            />
            <Breadcrumbs />
            <div className="row">
                <div className="col-lg-8">
                    <img
                        className="img-fluid rounded"
                        src="/img/Blog Banners/900x300/spicy-available.png"
                        alt=""
                    />
                    <hr />
                    <p>Posted on March 19, 2022</p>
                    <hr />
                    <p className="lead">
                        We've been constantly looking for how we can better
                        serve our client base, and after listening to feedback
                        and trialing flavor profiles, we're pleased to announce
                        the viva spicy hotdog.
                    </p>
                    <br />
                    <p>
                        We have been urged from some of our clients to add
                        products that cater to the wider Asian market as our
                        products are primarily found in Asian supermarkets.
                        We're excited by this opportunity to expand our product
                        offerings, and will continue to welcome ideas for how we
                        can serve you or your client base better. If you do have
                        requests for products that you think would sell well,
                        please reach out to us through our{' '}
                        <a href="/../contact-us">contact us page</a>
                    </p>
                    <hr />
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}
export default SpicyHotdogs
