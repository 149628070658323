import PageTitle from '../components/PageTitle'
import Breadcrumbs from '../components/Breadcrumbs'
import ContactDetails from '../components/contact-us/ContactDetails'
import ContactUsForm from '../components/contact-us/ContactUsForm'

function ContactUs() {
    return (
        <div className={'container'}>
            <PageTitle title={'Contact Us'} />
            <Breadcrumbs />
            <ContactDetails />
            <ContactUsForm />
        </div>
    )
}

export default ContactUs
