import team from "./AboutUs.jpg";

function OurStory() {
  return (
    <div className="row">
      <ol
        className="breadcrumb rounded"
        style={{ backgroundColor: "#e9ecef", padding: 10 }}
      >
        <li className="breadcrumb-item">
          <a href="/">Home</a>
        </li>
        <li className="breadcrumb-item active">About</li>
      </ol>
      <div className="col-lg-6">
        <img className="img-fluid rounded mb-4" src={team} alt="" />
      </div>
      <div className="col-lg-6">
        <h2>Our Story</h2>
        <p>
          FTG Foods Canada Ltd, named after three siblings, was established in
          September 2015 as a fully Filipino-Canadian owned and operated
          company. We are a fast-growing family business that manufactures meat
          products specifically for the Filipino market, and are known in Canada
          for being the first manufacturers of red hotdogs. We offer a line of
          products from authentic Filipino sausages to specialty seafood
          products. Over the last 5 years we have grown exponentially; from our
          humble beginnings in Calgary, Alberta, we now distribute to over 50
          Filipino and Asian Supermarkets and Restaurants across Canada.
        </p>
        <p>
          FTG Foods Canada Ltd. is dedicated to providing the best quality meat
          products and the most authentic Filipino taste. We strive daily to
          bring the taste of home to Filipinos in Canada. We are the #1 seller
          of Filipino style hotdogs, and are rapidly gaining popularity amongst
          the Filipino-Canadians and patrons who just can't get enough of our
          juicy hotdogs.
        </p>
      </div>
    </div>
  );
}

export default OurStory;
