import PageTitle from '../../components/PageTitle'
import Breadcrumbs from '../../components/Breadcrumbs'
import SubProductsTable from '../../components/products/SubProductsTable'
import ProductsList from '../../lib/ProductsList'
import { ProductType } from '../../lib/ProductType'

function Hotdogs() {
    let hotDogProducts: Array<ProductType> = []
    ProductsList.forEach((product) => {
        if (product.category === 'Hotdogs') {
            hotDogProducts.push(product)
        }
    })

    return (
        <div className={'container'}>
            <PageTitle title={'Hotdogs'} />
            <Breadcrumbs />
            <SubProductsTable products={hotDogProducts} />
        </div>
    )
}

export default Hotdogs
