function SponsorshipCard() {
    return (
        <div className="col-md-4">
            <div className="card my-4">
                <h5 className="card-header">Sponsorship Opportunities</h5>
                <div className="card-body">
                    Is there an initiative you'd like our support with? We'd
                    love to know if we might be a good fit to help out as a
                    sponsor. Contact us today to see if there's a good fit!
                </div>
            </div>
        </div>
    )
}
export default SponsorshipCard
