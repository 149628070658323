const RecipesList = [
    {
        name: 'Meaty Juicy Fiesta Pasta',
        url: 'fiesta-pasta',
        imgSlug: 'fiestapasta',
        description:
            'Looking for a healthy pasta dish that you can serve that will please both adults and the kids? Try the Meaty Juicy Fiesta Pasta!',
        ingredientsList: [
            '1 tbsp olive oil',
            '4 pc MEATY JUICY HOTDOG JUMBO, sliced diagonally',
            '1 large onion, chopped',
            '2 garlic cloves, crushed',
            '2 cups broccoli (boil 3 to 4 mins until tender)',
            '1 bell pepper, chopped',
            '1 tsp dry basil',
            '1 tsp chilli powder',
            '1 tsp sugar',
            'Salt and Black Pepper to taste',
            '400g can chopped tomatoes',
            '300g short pasta such as fusilli or farfalle (just over half a 500g bag) cooked according to packaging',
        ],
        instructionsList: [
            'Heat the oil in a large frying pan and sauté the onion, garlic, and bell pepper cooking them until they have softened. Add hotdog and cook for 2 minutes more.',
            'Stir in the chilli powder, basil, sugar, and tomatoes, bring the sauce to the boil then turn the heat down and let it bubble for about 10 minutes. Add sugar to taste.',
            'Toss pasta and broccoli in sauce and season with salt and pepper.',
        ],
    },
    {
        name: 'Meaty Juicy Macaroni Soup',
        url: 'macaroni-soup',
        imgSlug: 'macaronisoup',
        description: `Cold rainy day? Try our healthy meaty juicy macaroni soup. 🙂 A simple, rich, and tasty soup that will surely get rid of winter chills.`,
        ingredientsList: [
            '4 pcs meaty juicy hotdog (sliced)',
            '½ lb elbow macaroni',
            '2 stalks celery, minced',
            '1 medium yellow onion, minced',
            '½ head small cabbage, sliced',
            '1 large carrot, diced',
            '4 cups chicken broth',
            '4 cups water',
            '½ cup fresh milk or 1 (14 oz.) can evaporated milk',
            '4 tablespoon of canola oil',
            'Salt and pepper to taste',
        ],
        instructionsList: [
            'Sauté the onion until it turns medium brown. Add hotdog, celery, and carrot. Continue to sauté for 2 to 3 minutes.',
            'Quickly stir and then pour 4 cups of chicken broth. Also pour the 4 cups of water. Let boil.',
            'Cover and cook for 3 to 5 minutes.',
            'Put the elbow macaroni in the pot. Cook for 10 minutes. Add the cabbage. Stir and cook for 3 to 5 minutes. Note: you can add more water if needed.',
            'Pour the evaporated milk. Stir, let boil, cover and cook for another 3 minutes.',
            'Add salt and pepper to taste.',
        ],
    },
    {
        name: 'Cheesy Juicy Grilled Cheese',
        url: 'grilled-cheese',
        imgSlug: 'grilledcheese',
        description: `Cheese on top of Cheese ❤ Let your kids feel the love with FTG's
    Cheesy Juicy Grilled Cheese Sandwich. A favorite BAON/ Snack that
    your kids will enjoy at school 🙂`,
        ingredientsList: [
            'Cheesy Juicy hotdog',
            '2 slices of bread',
            '1 tablespoon of yellow mustard',
            '2 slices of cheese',
            '1/4 cup of butter, melted',
        ],
        instructionsList: [
            'Preheat oven to 350 degrees (F).',
            'Place bread slices on baking pan. Spread each slice with mustard and add 1 slice cheese.',
            'Brush each triangle with melted butter (top and bottom).',
            'Switch oven to "Broil" and cook sandwiches until golden brown, about 7 minutes on each side.',
            'Remove from oven and place on serving plate. Serve while hot.',
        ],
    },
]

export default RecipesList
