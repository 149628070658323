import community from "./community.jpg";

function CommunityEngagement() {
  return (
    <div>
      <div className="row mt-5">
        <div className="col-lg-6">
          <h2>How Our Products Support You</h2>
          <p>
            As a family owned and operated company, we understand the importance
            of supporting those in our community. Our community initiatives
            include:
          </p>
          <ul>
            <li>
              <strong>Our free yearly Calgary Stampede buffet breakfast</strong>
            </li>
            <li>Sponsor of the Fort McMurray Filipino Basketball League</li>
            <li>
              Team sponsor for the V League Winnipeg women's volleyball league
            </li>
            <li>Supporter of Nature's COVID-19 outreach program</li>
          </ul>
          <p>
            We're constantly on the lookout for ways to support out community
            efforts and initiatives to enhance Filipino-Canadian cultural
            development and support. Got an initiative that might need some
            support? Send us a message!
          </p>
        </div>
        <div className="col-lg-6 d-flex flex-wrap align-items-center">
          <img
            className={"rounded img-fluid"}
            src={community}
            alt="Community"
          />
        </div>
      </div>
    </div>
  );
}

export default CommunityEngagement;
