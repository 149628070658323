import grill from './grill.jpg'
import longganisa from './longganisa.jpg'
import fishballs from './fishballs.jpg'

function ProfileCarousel() {

    const carouselBackgroundImageStyles = {
        backgroundSize: 'cover',
        height: '65vh',
        minHeight: '300px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    }

    const carouselCaptionStyles = {
        backgroundColor: 'white',
        color: 'black',
        opacity: '.7',
        border: '1px black',
        width: '100%',
        left: '0px',
    }

    return (
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="false">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active"
                        aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active"
                     style={{
                         ...carouselBackgroundImageStyles,
                         backgroundImage: `url(${grill})`,
                     }}>
                    <div
                        className="carousel-caption d-none d-md-block carousel-text-background"
                        style={carouselCaptionStyles}
                    >
                        <h3>Specialty Hotdogs</h3>
                        <h5>
                            View our extensive line of red hotdog flavours and sizes.
                        </h5>
                    </div>
                </div>
                <div className="carousel-item"
                     style={{
                         ...carouselBackgroundImageStyles,
                         backgroundImage: `url(${longganisa})`
                     }}>
                    <div
                        className="carousel-caption d-none d-md-block carousel-text-background"
                        style={carouselCaptionStyles}
                    >
                        <h3>Specialty Meats</h3>
                        <h5>
                            View our products page for all of our specialty meats offerings.
                        </h5>
                    </div>
                </div>
                <div className="carousel-item" style={{
                    ...carouselBackgroundImageStyles,
                    backgroundImage: `url(${fishballs})`
                }}>
                    <div
                        className="carousel-caption d-none d-md-block carousel-text-background"
                        style={carouselCaptionStyles}
                    >
                        <h3>Seafood</h3>
                        <h5>
                            We offer both kikiam and fishballs. See our products page for more
                            information.
                        </h5>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export default ProfileCarousel;