function CookingVideo() {
    return (
        <div style={{textAlign: 'center'}}>

            <iframe className="my-3" frameBorder="0" width="736" height="414"
                    src="https://biteable.com/watch/embed/winter-sale-slideshow-copy-2692009" allowFullScreen={true}
                    allow="autoplay">
            </iframe>
        </div>
    )
}

export default CookingVideo;