import PageTitle from '../../components/PageTitle'
import Breadcrumbs from '../../components/Breadcrumbs'
import SubProductsTable from '../../components/products/SubProductsTable'
import ProductsList from '../../lib/ProductsList'
import { ProductType } from '../../lib/ProductType'

function Seafood() {
    let seafoodProducts: Array<ProductType> = []
    ProductsList.forEach((product) => {
        if (product.category === 'Seafood') {
            seafoodProducts.push(product)
        }
    })

    return (
        <div className={'container'}>
            <PageTitle title={'Seafood'} />
            <Breadcrumbs />
            <SubProductsTable products={seafoodProducts} />
        </div>
    )
}

export default Seafood
