import Breadcrumbs from '../components/Breadcrumbs'
import PageTitle from '../components/PageTitle'
import RecipeList from '../components/recipes/RecipeList'

function Recipes() {
    return (
        <div className="container">
            <PageTitle title={'Recipes'} />
            <Breadcrumbs />
            <RecipeList />
        </div>
    )
}

export default Recipes
