import Breadcrumbs from '../components/Breadcrumbs'
import PageTitle from '../components/PageTitle'
import { RecipeType } from '../lib/RecipeType'
import RecipesList from '../lib/RecipesList'
import RecipeDetails from '../components/recipes/RecipeDetails'
import { useLocation } from 'react-router-dom'
function Recipe() {
    const location = useLocation()
    const paths = location.pathname.split('/')
    const recipeUrl = paths[paths.length - 1]

    const recipeArray = RecipesList.filter((recipe) => {
        return recipe.url === recipeUrl
    })
    const recipe: RecipeType = recipeArray[0]
    return (
        <div className="container">
            <PageTitle title={recipe.name} />
            <Breadcrumbs />
            <RecipeDetails recipe={recipe} />
        </div>
    )
}
export default Recipe
