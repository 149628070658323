function NavbarLayout() {
    return (<nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
        <div className="container">
            <a className="navbar-brand" href="/">FTG Foods Canada</a>
            <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="/about-us">About</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="" role="button" data-bs-toggle="dropdown"
                           aria-expanded="false">
                            Products
                        </a>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="/products">View All</a></li>
                            <li><a className="dropdown-item" href="/products/viva">Viva</a></li>
                            <li><a className="dropdown-item" href="/products/hotdogs">Hotdogs</a></li>
                            <li><a className="dropdown-item" href="/products/specialty-meats">Specialty Meats</a></li>
                            <li><a className="dropdown-item" href="/products/seafood">Seafood</a></li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/cooking-instructions">Cooking Instructions</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="/recipes">Recipes</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="/contact-us">Contact Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/blog">Blog</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/find-retailers">Find Retailers</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>)
}

export default NavbarLayout