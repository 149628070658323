import OurCustomers from "../components/about-us/our-customers/OurCustomers";
import PageTitle from "../components/PageTitle";
import OurStory from "../components/about-us/our-story/OurStory";

function AboutUs() {
    return (
        <div className={'container'}>
            <PageTitle title={'About Us'}/>
            <OurStory/>
            <OurCustomers/>
        </div>
    );
}

export default AboutUs;