import kikiam from './kikiam.jpg'
import cheesyjuicy from './cheesy-juicy.jpg'
import meatyjuicy from './meaty-juicy.jpg'

function TopProducts() {
    return (
        <div className="mt-4">
            <h2>Top Selling Products</h2>
            <div className="row mt-3">
                <div className="col-lg-4 col-sm-12 portfolio-item">
                    <div className="card h-100">
                        <a href="/products/hotdogs"><img
                            className="card-img-top"
                            src={meatyjuicy}
                            alt=""
                        /></a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="/products/hotdogs">The Original Meaty Juicy</a>
                            </h4>
                            <p className="card-text">
                                The original meaty juicy hotdog which started it all! This product
                                comes in cocktail, regular and jumbo pack sizes so you can enjoy our
                                products at your next family dinner or at the next barbecue with
                                your friends.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 portfolio-item">
                    <div className="card h-100">
                        <a href="/products/hotdogs/cheesy-juicy"><img
                            className="card-img-top"
                            src={cheesyjuicy}
                            alt=""
                        /></a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="/products/hotdogs/cheesy-juicy">The Cheesy Juicy</a>
                            </h4>
                            <p className="card-text">
                                A favourite of our young and young-at-heart. The explosion of
                                cheesy, juicy flavours combines to make a certified top-seller! You
                                won't have any problem making sure your pickier eaters grow big and
                                strong with the cheesy juicy.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 portfolio-item">
                    <div className="card h-100">
                        <a href="/products/seafood/kikiam"><img
                            className="card-img-top"
                            src={kikiam}
                            alt=""
                        /></a>
                        <div className="card-body">
                            <h4 className="card-title">
                                <a href="/products/seafood/kikiam">The Special Kikiam</a>
                            </h4>
                            <p className="card-text">
                                Our seafood kikiam is component used in many Filipino dishes. We
                                elevate the traditional Filipino style kikiam by using a wider
                                variety of seafood which gives our kikiam a balanced flavour and
                                texture.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TopProducts;