import { ProductType } from '../../lib/ProductType'

function SubProductsTable({ products }: { products: ProductType[] }) {
    return (
        <div>
            <div className="row">
                {products.map((item, index) => (
                    <div className="col-lg-6 portfolio-item mb-4" key={index}>
                        <div className="card h-100">
                            <a
                                href={`/products/${item.breadCrumb}/${item.url}`}
                            >
                                <img
                                    className="card-img-top"
                                    src={`/img/Products/${item.imgTable}`}
                                    alt=""
                                />
                            </a>
                            <div className="card-body">
                                <h4 className="card-title">
                                    <a
                                        href={`/products/${item.breadCrumb}/${item.url}`}
                                    >
                                        {item.name}
                                    </a>
                                </h4>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SubProductsTable
