import ProductsList from '../../lib/ProductsList'
import { Link } from 'react-router-dom'
function FullProductsTable() {
    return (
        <div>
            <div className="row">
                {ProductsList.map((item, index) => (
                    <div
                        className="col-lg-3 col-md-4 col-sm-6 portfolio-item mb-4"
                        key={index}
                    >
                        <div className="card h-100">
                            <Link
                                to={`/products/${item.breadCrumb}/${item.url}`}
                            >
                                <img
                                    className="card-img-top"
                                    src={`/img/Products/${item.imgTable}`}
                                    alt=""
                                />
                            </Link>
                            <div className="card-body">
                                <h4 className="card-title">
                                    <Link
                                        to={`/products/${item.breadCrumb}/${item.url}`}
                                    >
                                        {item.name}
                                    </Link>
                                </h4>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default FullProductsTable
