import { Link } from 'react-router-dom'

function NoMatch() {
    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: '80vh' }}
        >
            <div className="text-center">
                <h2>The page you're looking for has moved</h2>
                <Link to="/">Go to the home page</Link>
            </div>
        </div>
    )
}

export default NoMatch
