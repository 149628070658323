import { BlogType } from '../../lib/BlogType'

function BlogCard({ blog, index }: { blog: BlogType; index: number }) {
    return (
        <div className="card mb-4">
            <img
                className="card-img-top"
                src={`img/Blog Banners/750x300/${blog.imgSlug}.png`}
                alt="Card image cap"
            />
            <div className="card-body">
                <h2 className="card-title">{blog.blogHeader}</h2>
                <p className="card-text">{blog.blogBlurb}</p>
                <a href={`/blog${blog.url}`} className="btn btn-primary">
                    Read More &rarr;
                </a>
            </div>
            <div className="card-footer text-muted">Posted on {blog.date}</div>
        </div>
    )
}
export default BlogCard
