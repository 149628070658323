import { RecipeType } from '../../lib/RecipeType'
function RecipeDetails({ recipe }: { recipe: RecipeType }) {
    return (
        <div className="row">
            <div className="col-md-8">
                <img
                    className="img-fluid"
                    src={`/img/Recipes/750x500/${recipe.imgSlug}.png`}
                    alt=""
                />
            </div>
            <div className="col-md-4">
                <h3 className="my-3">{recipe.name}</h3>
                <p>{recipe.description}</p>
                <h3 className="my-3">Ingredients</h3>
                <ul>
                    {recipe.ingredientsList.map((ingredients) => (
                        <li>{ingredients}</li>
                    ))}
                </ul>
                <h3 className="my-3">Instructions</h3>
                <ol>
                    {recipe.instructionsList.map((instructions) => (
                        <li>{instructions}</li>
                    ))}
                </ol>
            </div>
        </div>
    )
}
export default RecipeDetails
