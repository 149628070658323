import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SponsorshipCard from '../../components/blog/SponsorshipCard'
import { FacebookEmbed } from 'react-social-media-embed'

function Federal() {
    return (
        <div className="container">
            <PageTitle title={'FTG Foods Goes Federal'} />
            <Breadcrumbs />
            <div className="row">
                <div className="col-lg-8">
                    <img
                        className="img-fluid rounded"
                        src="/img/Blog Banners/900x300/federal.png"
                        alt=""
                    />
                    <hr />
                    <p>Posted on May 23, 2020</p>
                    <hr />
                    <p className="lead">
                        We've spent over 4 years building up the FTG brand.
                        We're finally pleased to announce the completion of our
                        federal facility which will enable us to process and
                        sell our product across provincial boundaries. This
                        means that customers from across Canada can now request
                        our products at their current grocery stores!
                    </p>
                    <p>
                        Our new facility is located at 7007 30 St SE, Calgary
                        AB. We've closed our old provincial facility at
                        Erinwoods to focus on selling all our products with
                        Canadian Food Inspection Agency's stamp of approval.
                        We're very pleased with our collaboration with the
                        government inspectors, and all the work that's gone into
                        ensuring that we pass inspection on a daily basis and
                        are certified for sales in both the USA and Canada.
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FacebookEmbed
                            url="https://www.facebook.com/1054465537/videos/10219400312732568/"
                            width={550}
                        />
                    </div>
                    <br />
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}

export default Federal
