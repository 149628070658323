import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SponsorshipCard from '../../components/blog/SponsorshipCard'

function TorontoStreetFestival() {
    return (
        <div className="container">
            <PageTitle
                title={
                    'Check us out at the Toronto Street Festival July 23 and 24!'
                }
            />
            <Breadcrumbs />
            <div className="row">
                <div className="col-lg-8">
                    <img
                        className="img-fluid rounded"
                        src="/img/Blog Banners/900x300/streetfoodfestival.png"
                        alt=""
                    />
                    <hr />
                    <p>Posted on July 17, 2022</p>
                    <hr />
                    <p className="lead">
                        We've been making great headway in the Ontario market;
                        recently our products have made their way onto the
                        shelves of large chain grocery store FreshCo, meaning
                        our products will now be available in an additional 6
                        stores in the GTA.
                    </p>
                    <br />
                    <p>
                        Given our success in FreshCo's stores, we've decided to
                        partner up in celebrating Filipino culture and food at
                        this huge street celebration. You'll be able to find our
                        tent in the food vendors strip of the festival. Come out
                        and try our Viva lineup of hotdogs, which are now
                        available in FreshCo. For more information on the event,
                        check out the event website{' '}
                        <a href="https://www.funphilippinestoronto.com/">
                            here
                        </a>
                        , and for a full list of our retailer locations in the
                        GTA, check out our{' '}
                        <a href="/../find-retailers">retailers page</a>
                    </p>
                    <img
                        src="/img/torontostreetfestival.png"
                        className="img-fluid"
                    />
                    <hr />
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}
export default TorontoStreetFestival
