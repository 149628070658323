import FullProductsTable from '../components/products/FullProductsTable'
import Breadcrumbs from '../components/Breadcrumbs'
import PageTitle from '../components/PageTitle'

function Products() {
    return (
        <div className={'container'}>
            <PageTitle title={'Products'} />
            <Breadcrumbs />
            <FullProductsTable />
        </div>
    )
}

export default Products
