import PageTitle from "../components/PageTitle";
import CookingVideo from "../components/cooking-instructions/CookingVideo";

function CookingInstructions() {
    return (
        <div className="container">
            <PageTitle title={'Cooking Instructions'}/>
            <CookingVideo/>
        </div>
    );
}

export default CookingInstructions;