import PageTitle from '../../components/PageTitle'
import Breadcrumbs from '../../components/Breadcrumbs'
import SubProductsTable from '../../components/products/SubProductsTable'
import ProductsList from '../../lib/ProductsList'
import { ProductType } from '../../lib/ProductType'

function Viva() {
    let vivaProducts: Array<ProductType> = []
    ProductsList.forEach((product) => {
        if (product.category === 'Viva') {
            vivaProducts.push(product)
        }
    })

    return (
        <div className={'container'}>
            <PageTitle title={'Viva'} />
            <Breadcrumbs />
            <SubProductsTable products={vivaProducts} />
        </div>
    )
}

export default Viva
