const ProductsList = [
    {
        category: 'Viva',
        url: 'spicy',
        breadCrumb: 'viva',
        imgSlug: '/profiles/jumbo.jpg',
        imgTable: '/viva-spicy.png',
        name: 'Viva Spicy Hotdog',
        description:
            'A taste that will hit the mark with Chinese clientele who are looking for something new.',
        mainIngredient: 'Pork',
        weight: '600g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'meaty',
        breadCrumb: 'viva',
        imgSlug: '/profiles/jumbo.jpg',
        imgTable: '/viva-sweetnjuicy.png',
        name: 'Viva Meaty Juicy',
        description:
            'The hotdog that started it all. This is the latest and greatest version of our hotdogs, and it continues to lead in sales nationwide. Find out what the rave is all about!',
        mainIngredient: 'Pork',
        weight: '600g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'meaty-cocktail',
        breadCrumb: 'viva',
        imgSlug: '/profiles/cocktail.jpg',
        imgTable: '/viva-meaty-cocktail.png',
        name: 'Viva Meaty Juicy Cocktail',
        description: 'A kid-friendly version of our best-selling product.',
        mainIngredient: 'Pork',
        weight: '200g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'cheesy',
        breadCrumb: 'viva',
        imgSlug: '/profiles/cheesy.jpg',
        imgTable: '/viva-cheesy.png',
        name: 'Viva Cheesy Juicy',
        description:
            'A kid-friendly red Hotdog that oozes cheese. What more is there to say? Satisfy your cravings and have a bite of the Filipino goodness made with the highest quality and care.',
        mainIngredient: 'Pork',
        weight: '600g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'cheesy-cocktail',
        breadCrumb: 'viva',
        imgSlug: '/profiles/cocktail.jpg',
        imgTable: '/viva-cheesy-cocktail.png',
        name: 'Viva Cheesy Juicy Cocktail',
        description:
            'A red Hotdog that oozes cheese. What more is there to say? Satisfy your cravings and have a bite of the Filipino goodness made with the highest quality and care.',
        mainIngredient: 'Pork',
        weight: '200g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'sweetandjuicy',
        breadCrumb: 'viva',
        imgSlug: '/profiles/jumbo.jpg',
        imgTable: '/viva-sweetnjuicy.png',
        name: 'Viva Sweet & Juicy',
        description:
            'A sweeter, more umami infused version of our flagship, Meaty Juicy.',
        mainIngredient: 'Pork',
        weight: '600g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'hamondebola',
        breadCrumb: 'viva',
        imgSlug: '/viva-hamondebola.png',
        imgTable: '/viva-hamondebola.png',
        name: 'Viva Hamon de Bola',
        description:
            "We've been selling out of these seasonal hams each Christmas for over 3 years. Perfect for company gifts and holiday givaways.",
        mainIngredient: 'Pork',
        weight: '1.2kg',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'holidayham',
        breadCrumb: 'viva',
        imgSlug: '/viva-holidayham.png',
        imgTable: '/viva-holidayham.png',
        name: 'Viva Holiday Ham',
        description:
            "We've been selling out of these seasonal hams each Christmas for over 3 years. Perfect for company gifts and holiday givaways.",
        mainIngredient: 'Pork',
        weight: '1.2kg',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'tocino',
        breadCrumb: 'viva',
        imgSlug: '/viva-tocino.png',
        imgTable: '/viva-tocino.png',
        name: 'Viva Pork Tocino',
        description: 'A staple on any Filipino breakfast menu.',
        mainIngredient: 'Pork',
        weight: '350g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'tapa',
        breadCrumb: 'viva',
        imgSlug: '/viva-tapa.png',
        imgTable: '/viva-tapa.png',
        name: 'Viva Beef Tapa',
        description:
            'A traditional Filipino preparation of beef, it is a combination of sweet, sour and salty flavours.',
        mainIngredient: 'Beef',
        weight: '350g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'garlic',
        breadCrumb: 'viva',
        imgSlug: '/viva-garlic.png',
        imgTable: '/viva-garlic.png',
        name: 'Viva Garlic Longganisa',
        description:
            'Longganisa is a staple on any Filipino breakfast menu. The garlic longganisa flavour is one of the mainstay flavours of this highly popularized breakfast sausage.',
        mainIngredient: 'Pork',
        weight: '350g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Viva',
        url: 'hamonado',
        breadCrumb: 'viva',
        imgSlug: '/viva-hamonado.png',
        imgTable: '/viva-hamonado.png',
        name: 'Viva Hamonado Longganisa',
        description:
            'Longganisa is a staple on any Filipino breakfast menu. The sweet hamonado longganisa flavour is one of the mainstay flavours of this highly popularized breakfast sausage.',
        mainIngredient: 'Pork',
        weight: '350g',
        packsPerBox: 'Please contact sales for details',
    },
    {
        category: 'Hotdogs',
        url: 'mj-jumbo-825',
        breadCrumb: 'hotdogs',
        imgSlug: '/profiles/jumbo.jpg',
        imgTable: '/jumbo.jpg',
        name: 'Meaty Juicy Jumbo Size - 825g',
        description:
            'Red Hotdog. What more is there to say? All Filipinos can see the signature hotdog that is now available in Canada. Satisfy your cravings and have a bite of the Filipino goodness made fresh everyday in Canada.',
        mainIngredient: 'Pork',
        weight: '825g',
        packsPerBox: '15',
    },
    {
        category: 'Hotdogs',
        url: 'mj-jumbo-400',
        breadCrumb: 'hotdogs',
        imgSlug: '/profiles/jumbo.jpg',
        imgTable: '/jumbo.jpg',
        name: 'Meaty Juicy Jumbo Size - 400g',
        description:
            'Red Hotdog. What more is there to say? All Filipinos can see the signature hotdog that is now available in Canada. Satisfy your cravings and have a bite of the Filipino goodness made fresh everyday in Canada.',
        mainIngredient: 'Pork',
        weight: '400g',
        packsPerBox: '40',
    },
    {
        category: 'Hotdogs',
        url: 'mj-regular-825',
        breadCrumb: 'hotdogs',
        imgSlug: '/profiles/regular.jpg',
        imgTable: '/regular.jpg',
        name: 'Meaty Juicy Regular Size - 825g',
        description:
            'Red Hotdog. What more is there to say? All Filipinos can see the signature hotdog that is now available in Canada. Satisfy your cravings and have a bite of the Filipino goodness made fresh everyday in Canada.',
        mainIngredient: 'Pork',
        weight: '825g',
        packsPerBox: '15',
    },
    {
        category: 'Hotdogs',
        url: 'mj-regular-400',
        breadCrumb: 'hotdogs',
        imgSlug: '/profiles/regular.jpg',
        imgTable: '/regular.jpg',
        name: 'Meaty Juicy Regular Size - 400g',
        description:
            'Red Hotdog. What more is there to say? All Filipinos can see the signature hotdog that is now available in Canada. Satisfy your cravings and have a bite of the Filipino goodness made fresh everyday in Canada.',
        mainIngredient: 'Pork',
        weight: '400g',
        packsPerBox: '40',
    },
    {
        category: 'Hotdogs',
        url: 'cocktail',
        breadCrumb: 'hotdogs',
        imgSlug: '/profiles/cocktail.jpg',
        imgTable: '/cocktail.png',
        name: 'Meaty Juicy Cocktail',
        description:
            'Having a kid’s party? A celebration will not be complete without our perfect bite size hotdogs.',
        mainIngredient: 'Pork',
        weight: '200g',
        packsPerBox: '50',
    },
    {
        category: 'Hotdogs',
        url: 'cheesy-juicy',
        breadCrumb: 'hotdogs',
        imgSlug: '/profiles/cheesy.jpg',
        imgTable: '/cheesy.jpg',
        name: 'Cheesy Juicy',
        description:
            'Cheese, cheese, cheese. Nothing can go wrong with the added richness of melted cheese. Cheesy Juicy hotdogs are the ultimate love of kids and adults alike.',
        mainIngredient: 'Pork',
        weight: '300g',
        packsPerBox: '40',
    },
    {
        category: 'Hotdogs',
        url: 'sweetnjuicy',
        breadCrumb: 'hotdogs',
        imgSlug: '/sweetnjuicy.jpg',
        imgTable: '/sweetnjuicy.jpg',
        name: "Sweet n' Juicy",
        description:
            "Have you ever tried hotdog and longganisa together? Try out Sweet n' Juicy. It has the firmness of a sausage and the sweet garlic flavour of longganisa.",
        mainIngredient: 'Pork',
        weight: '300g',
        packsPerBox: '40',
    },
    {
        category: 'Specialty Meats',
        url: 'hamonado-longganisa',
        breadCrumb: 'specialty-meats',
        imgSlug: '/profiles/hamonadolongganisa.jpg',
        imgTable: '/hamonadolongganisa.jpg',
        name: 'Hamonado Longanisa',
        description:
            'A classic variation of Filipino pork sausage that combines a balance of sweet and spicey flavours.',
        mainIngredient: 'Pork',
        weight: '350g',
        packsPerBox: '30',
    },
    {
        category: 'Specialty Meats',
        url: 'garlic-longganisa',
        breadCrumb: 'specialty-meats',
        imgSlug: '/profiles/garliclongganisa.jpg',
        imgTable: '/garliclongganisa.jpg',
        name: 'Garlic Longganisa',
        description:
            'Not used to the sweet traditional longganisa? Try our inspired Vigan Ilocano sausage with plenty of garlic and spices in the mix. ',
        mainIngredient: 'Pork',
        weight: '350g',
        packsPerBox: '30',
    },
    {
        category: 'Specialty Meats',
        url: 'pork-tocino',
        breadCrumb: 'specialty-meats',
        imgSlug: '/tocino.jpg',
        imgTable: '/tocino.jpg',
        name: 'Pork Tocino',
        description:
            'A Filipino breakfast is not complete without tocino. A sweetened cured pork dish that goes excellently well with sinangag (fried rice) and itlog na maalat (salted egg).',
        mainIngredient: 'Pork',
        weight: '350g',
        packsPerBox: '40',
    },
    {
        category: 'Specialty Meats',
        url: 'beef-tapa',
        breadCrumb: 'specialty-meats',
        imgSlug: '/beeftapa.jpg',
        imgTable: '/beeftapa.jpg',
        name: 'Beef Tapa',
        description:
            'Thinly sliced beef cured with salt and spices. Have a taste of the Filipino version of beef jerky.',
        mainIngredient: 'Beef',
        weight: '350g',
        packsPerBox: '40',
    },
    {
        category: 'Seafood',
        url: 'kikiam',
        breadCrumb: 'seafood',
        imgSlug: '/profiles/kikiam.jpg',
        imgTable: '/kikiam.jpg',
        name: 'Special Kikiam',
        description:
            'Try our new addition of Filipino Specialty, made from variety of seafood.',
        mainIngredient: 'Fish/Shrimp',
        weight: '300g',
        packsPerBox: '40',
    },
    {
        category: 'Seafood',
        url: 'fishballs',
        breadCrumb: 'seafood',
        imgSlug: '/profiles/fishball.jpg',
        imgTable: '/fishball.jpg',
        name: 'Fishballs',
        description:
            'This round-shaped food is a meatball-like product made from fish meat that is most often sold by street vendors in the Philippines. It is deep fried and accompanied by a sweet-spicy sauce.',
        mainIngredient: 'Fish',
        weight: '300g',
        packsPerBox: '40',
    },
    {
        category: 'Specialty Meats',
        url: 'holiday-ham',
        breadCrumb: 'specialty-meats',
        imgSlug: 'holidayham.png',
        imgTable: 'holidayham.png',
        name: 'Holiday Ham',
        description:
            'Celebrate the festivity by enjoying the sweet pineapple glazed ham contrasted with the salt juicy flavor of succulent pork. Seasonal availability, while supplies last.',
        mainIngredient: 'Pork',
        weight: 'Currently Unavailable',
        packsPerBox: 'Currently Unavailable',
    },
]

export default ProductsList
