import Breadcrumbs from '../../components/Breadcrumbs'
import PageTitle from '../../components/PageTitle'
import SponsorshipCard from '../../components/blog/SponsorshipCard'

function GreaterTorontoArea() {
    return (
        <div className="container">
            <PageTitle
                title={
                    'FTG Foods products are now available in the Greater Toronto Area'
                }
            />
            <Breadcrumbs />
            <div className="row">
                <div className="col-lg-8">
                    <img
                        className="img-fluid rounded"
                        src="/img/Blog Banners/900x300/toronto.png"
                        alt=""
                    />
                    <hr />
                    <p>Posted on July 18, 2021</p>
                    <hr />
                    <p className="lead">
                        We are thrilled to announce that we have established
                        ourselves in the Greater Toronto Area, and have over 28
                        stores where you can find our products. A special thank
                        you to all of our vendors now carrying FTG Foods
                        products!
                    </p>

                    <br />

                    <h4>
                        Find us in your favourite asian specialty grocery store
                    </h4>
                    <br />
                    <div className="row">
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/ajax.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/ample.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/bestco.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/bluesky.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/btrust.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/centra.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/freshland.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/fusion.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/fvfoods.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/hongtai.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/jianhing.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/nations.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/pacific.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/seasons.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/skyland.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/sunny.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/tasteco.jpg"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-2 col-sm-4 mb-4">
                            <img
                                className="img-fluid"
                                src="/img/gta-stores/terra.jpg"
                                alt=""
                            />
                        </div>
                    </div>

                    <iframe
                        src="https://www.google.com/maps/d/embed?mid=1MEolzTxyv46yF4_wawAR3ulVLQ_IlrN6"
                        width="640"
                        height="480"
                    ></iframe>
                    <hr />
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}
export default GreaterTorontoArea
