import Breadcrumbs from '../components/Breadcrumbs'
import PageTitle from '../components/PageTitle'
import { ProductType } from '../lib/ProductType'
import ProductsList from '../lib/ProductsList'
import ProductDetails from '../components/products/ProductDetails'
import { useLocation } from 'react-router-dom'

function Product() {
    const location = useLocation()
    const paths = location.pathname.split('/')
    const productName = paths[paths.length - 1]

    const productArray = ProductsList.filter((prod) => {
        return prod.url === productName
    })
    const product: ProductType = productArray[0]

    return (
        <div className="container">
            <PageTitle title={product.name} />
            <Breadcrumbs />
            <ProductDetails product={product} />
        </div>
    )
}

export default Product
