import PageTitle from "../components/PageTitle";
import RetailersMap from "../components/find-retailers/RetailersMap";

function FindRetailers() {
    return (
        <div className="container">
            <PageTitle title={'Find Retailers'}/>
            <RetailersMap/>
        </div>
    );
}

export default FindRetailers;