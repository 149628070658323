import Breadcrumbs from '../components/Breadcrumbs'
import PageTitle from '../components/PageTitle'
import BlogCard from '../components/blog/BlogCard'
import SponsorshipCard from '../components/blog/SponsorshipCard'
import BlogList from '../lib/BlogList'
function Blog() {
    return (
        <div className="container">
            <PageTitle title={'Blog'} />
            <Breadcrumbs />
            <div className="row">
                <div className="col-md-8">
                    {BlogList.map((blog, index) => (
                        <div>
                            <BlogCard blog={blog} index={index} />
                        </div>
                    ))}
                </div>
                <SponsorshipCard />
            </div>
        </div>
    )
}

export default Blog
