const BlogList = [
    {
        url: '/toronto-street-festival',
        title: 'Check us out at the Toronto Street Festival July 23 and 24!',
        date: 'March 19, 2022',
        imgSlug: 'streetfoodfestival',
        blogHeader:
            'Check us out at the Toronto Street Festival July 23 and 24!',
        blogBlurb:
            "You'll be able to find our products on sale at this year's Toronto Street Festival July 23 and 24. Come enjoy the music, performances, and food!",
    },
    {
        url: '/spicy-hotdogs',
        title: 'FTG Foods adds spicy hotdogs to their Viva lineup',
        date: 'March 19, 2022',
        imgSlug: 'spicy-available',
        blogHeader: "We've upped the heat in our latest flavour",
        blogBlurb:
            "We've been constantly looking for how we can better serve our client base, and after listening to feedback and trialing flavor profiles, we're pleased to announce the viva spicy hotdog.",
    },
    {
        url: '/ontario-summer-sale',
        title: 'Check out our new promotional sales in Vaughan, Etobicoke, Mississauaga and Scarborough',
        date: 'July 18, 2021',
        imgSlug: 'ontario-summer-sale',
        blogHeader:
            'Check out our new promotional sales in Vaughan, Etobicoke, Mississauaga and Scarborough',
        blogBlurb:
            'With the recent launch of the Viva brand in Ontario, we are thrilled to announce that our products will be going on promotional sales in over 5 cities in the Greater Toronto Area.',
    },
    {
        url: '/greater-toronto-area',
        title: 'FTG Foods products are now available in the Greater Toronto Area',
        date: 'July 18, 2021',
        imgSlug: 'toronto',
        blogHeader:
            'FTG Foods products are now available in the Greater Toronto Area',
        blogBlurb:
            "It's been just over a year now since we've gone federal, and we are pleased to announce that you can now find our products being carried in the Greater Toronto Area. To find the nearest grocery store to you, read the full article.",
    },
    {
        url: '/viva-packaging',
        title: `FTG Foods introduces the Viva brand`,
        date: 'Mar 28, 2021',
        imgSlug: 'viva',
        blogHeader: 'FTG Foods introduces the Viva brand',
        blogBlurb:
            "As we've grown this past year, we've decided to invest in better packaging that's more consumer friendly, and also update our brand to better reflect our values. You'll find our new and improved Viva lineup of products in the same supermarkets that you're used to.",
    },
    {
        url: '/covid-19',
        title: 'COVID-19 Response',
        date: 'May 23, 2020',
        imgSlug: 'covid',
        blogHeader: 'COVID-19 Response',
        blogBlurb:
            "We know that in stressful times, we often seek our comfort foods. We want all of our consumers to rest assured that we're taking all precautions necessary to ensure that our products are of the highest safety standards for the protection of our employees and our customers.",
    },
    {
        url: '/federal',
        title: 'FTG Foods Goes Federal',
        date: 'April 21, 2020',
        imgSlug: 'federal',
        blogHeader: 'FTG Foods goes federal!',
        blogBlurb:
            "We've been working for over 4 years to build a brand that is known across Canada. Check in with us for distribution of our products!",
    },
    {
        url: '/stampede',
        title: 'Stampede Breakfast',
        date: 'April 20, 2020',
        imgSlug: 'stampede',
        blogHeader: 'Stampede breakfast is postponed',
        blogBlurb:
            'The Calgary Stampede breakfast is our annual community event where we offer our menu to the community for free. With social distancing measures in place, we will have to postpone our event, however we look forward to hosting it once the government announces it is safe to do so.',
    },
]

export default BlogList
